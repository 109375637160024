import React from "react"
import { graphql } from "gatsby"
// markup
const VideoPage = ({ data }) => {
  if (typeof window != "undefined") {
    window.location.href = data.strapiVideo.url
  }

  return <div></div>
}
export default VideoPage

export const query = graphql`
  query ($uid: String) {
    strapiVideo(uid: { eq: $uid }) {
      url
    }
  }
`
